import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "SkyWay UG Tokyo #1 に参加してきました",
  "date": "2017-12-22T05:33:07.000Z",
  "slug": "entry/2017/12/22/143307",
  "tags": ["medley"],
  "hero": "./2017_12_22.png",
  "heroAlt": "skyway meetup"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、開発本部 平木です。2017/12/04(月)に `}<a parentName="p" {...{
        "href": "https://skyway.connpass.com/event/71316/"
      }}>{`SkyWay UG Tokyo #1`}</a>{` という勉強会がありました。弊社から開発本部の宍戸がセッション枠で発表させていただきましたので、イベントレポートをお送りします。`}</p>
    <h1>{`はじめに`}</h1>
    <p>{`弊社の運営サービスの 1 つである `}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`オンライン診察アプリ「CLINICS」`}</a>{` では運用初期の頃から医療機関と患者さんとの診察に SkyWay を使ったビデオチャットを導入しており、現在も Web / iOS / Android の各プラットフォームで SkyWay が稼動しています。
そのようなご縁もあり今回の勉強会では、CLINICS で SkyWay をどのように利用しているかをお話させていただきました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20171221/20171221191518.jpg",
      "alt": "20171221191518.jpg"
    }}></img>
    <h1>{`セッション`}</h1>
    <p>{`それでは、各セッションの流れなどをご紹介していきたいと思います。`}</p>
    <h2>{`はじめに`}</h2>
    <p>{`勉強会の冒頭、UG のキックオフと参加者の自己紹介タイムがありました。`}</p>
    <h3>{`UG キックオフ`}</h3>
    <p>{`NTT コミュニケーションズの仲さんから、SkyWay UG をなぜ作るのか?というキックオフから会がスタートしました。`}</p>
    <p>{`SkyWay の大きなミッションとして `}<strong parentName="p">{`リアルタイムコミュニケーションを身近にする`}</strong>{` というものがあり、そのためには `}<strong parentName="p">{`開発者にサービス自体がフレンドリーであるべき`}</strong>{` であるという理念があるそうです。`}</p>
    <p>{`開発者フレンドリーであるための一環として `}<strong parentName="p">{`開発者コミュニティの運用をしていく`}</strong>{` ということになりこの SkyWay UG Tokyo が発足されたという経緯が説明されました。`}</p>
    <p>{`確かにこのような形で使っているサービスの UG が開催されると、サービスの開発者の方々や参加している他のユーザさん達と気軽に交流できるなというのを今回参加して実感しました。`}</p>
    <p>{`SkyWay UG は東京だけではなく `}<a parentName="p" {...{
        "href": "https://skyway.connpass.com/event/74875/"
      }}>{`関西`}</a>{` や `}<a parentName="p" {...{
        "href": "https://skyway.connpass.com/event/74270/"
      }}>{`福岡`}</a>{` でも続々と発足するとのことで、他の開発者サービスと同じように盛り上がっていくのではないでしょうか。`}</p>
    <h3>{`参加者自己紹介`}</h3>
    <p>{`キックオフが終わったところで、参加者の自己紹介タイムとなりました。`}</p>
    <p>{`30 人近くの参加者が順々に自己紹介していくという形だったのですが、個人的には珍しいなと思うのと同時に、懇親会で何をやっている方なのか?というのが分かりつつ話をすることができたという良さがありました。(参加者として自己紹介する勉強会は初めてでした!)`}</p>
    <p>{`参加者の感想としては`}</p>
    <ul>
      <li parentName="ul">{`自分達と同じ Web 業界の方がメイン…というわけでもなく、組み込みなどを仕事にしている方なども 1/3 くらいの割合でいたのが印象的だった`}</li>
      <li parentName="ul">{`実際にサービスやプロダクトに SkyWay を使っているという参加者の方が多かった`}
        <ul parentName="li">
          <li parentName="ul">{`とはいえ使ってないが、興味があって参加したという方も`}</li>
        </ul>
      </li>
      <li parentName="ul">{`中には弊社の CLINICS に興味があると言っていただけたりもした`}</li>
    </ul>
    <p>{`という感じでした。ハードウェアに SkyWay を組み込んで使うということをやっている方も多く、改めて幅広い開発者に受け入れられてるプラットフォームだと感じました。`}</p>
    <h2>{`Skype から SkyWay へ`}</h2>
    <p>{`最初のセッションは会場を提供されていた `}<a parentName="p" {...{
        "href": "https://www.rarejob.com/"
      }}>{`レアジョブ`}</a>{` の Inoue さんからの発表でした。`}</p>
    <p>{`Inoue さんは現在は主にフロントエンド開発をされているそうで、このセッションも JavaScript SDK の知見がいろいろされた有意義なものでした。`}</p>
    <p>{`レアジョブさんでは創業時から Skype を使ってサービスを運用していたそうなのですが、サードパーティに依存しすぎるとビジネスとして危ういということで、SkyWay への切り換えをしていっているそうです。`}</p>
    <p>{`SkyWay は 9 月からトライアル版から正式版を提供されていますが、まだなかなか情報が少ないということで、新 JavaScript SDK で開発していくなかで得た Tips が発表されました。`}</p>
    <p>{`実サービスを運用してる方ならではの、 `}<strong parentName="p">{`ビデオ・マイクのミュート`}</strong>{` や `}<strong parentName="p">{`ビデオチャット中のページ離脱防止策`}</strong>{` などの対応などは実用的で役立つものでした。`}</p>
    <p>{`Inoue さんもこの後の LT で発表された仲さんもおっしゃっていましたが、SkyWay というか WebRTC のデバッグには `}<a parentName="p" {...{
        "href": "chrome://webrtc-internls"
      }}>{`chrome://webrtc-internls`}</a>{` が重要だと改めて思った次第です。`}</p>
    <h2>{`オンライン診察アプリの作り方`}</h2>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/d16004ecfa7c41349fc892e693effe8d" title="オンライン診察アプリの作り方 / clinics-skyway-ug #1" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "420px"
    }} data-ratio="1.3333333333333333"></iframe>
    <p>{`弊社の宍戸の発表です。`}</p>
    <p>{`まずはメドレーと運営しているプロダクト紹介から始まり、そもそも CLINICS で実現したかったビデオチャットはどのような条件があったか?SkyWay を選んだ理由、SkyWay と Firebase Realtime Database を併用したオンライン診察の実装内容などをご紹介しました。`}</p>
    <p>{`駆け足でのご紹介にはなりましたが、実際に商用サービスでの事例ということもあり、おかげさまで好評でした。`}</p>
    <p>{`スライド中でも紹介した SkyWay と Firebase を使った通話制御は他のプロダクトでも使ってることが多いらしく、相性的にもお勧めなのではないでしょうか。`}</p>
    <h2>{`LT`}</h2>
    <p>{`セッション枠が終わり LT となったのですが、この LT が SkyWay の中の方達が担当するという豪華なものでした。`}</p>
    <h3>{`WebRTC で統計情報を収集する`}</h3>
    <p><a parentName="p" {...{
        "href": "https://qiita.com/yusuke84/items/dc6e5a4ed109c4631b66"
      }}>{`https://qiita.com/yusuke84/items/dc6e5a4ed109c4631b66`}</a></p>
    <p>{`NTT コミュニケーションズ仲さんの LT です。 `}<a parentName="p" {...{
        "href": "chrome://webrtc-internls"
      }}>{`chrome://webrtc-internls`}</a>{` で表示されるような統計情報を JavaScript SDK を使って表示することができるという解説とデモでした。`}</p>
    <p>{`WebRTC のデバッグや、ユーザからの問い合わせなどにすぐに使えそうな実践的な内容でした。が、現バージョンでは残念ながら、Android / iOS ではこの統計情報が取れない…とのことだったので、将来的には取れるようになると弊社でもデバックが捗るので首を長くして待ちたいと思います。`}</p>
    <p>{`紹介されている`}<a parentName="p" {...{
        "href": "https://www.callstats.io/"
      }}>{`callstats.io`}</a>{`は知らなかったので、参考になりました。行く行くは使ってみたいですね。`}</p>
    <h3>{`reCAPTCHA と API 認証で手軽に利用できて不正利用に強いアプリを作ろう`}</h3>
    <iframe src="//www.slideshare.net/slideshow/embed_code/key/bA04wVMkunLlbG" width="595" height="485" frameBorder="0" marginWidth="0" marginHeight="0" scrolling="no" style={{
      "border": "1px solid #CCC",
      "borderWidth": "1px",
      "marginBottom": "5px",
      "maxWidth": "100%"
    }} allowFullScreen> </iframe> <div style={{
      "marginBottom": "5px"
    }}> <strong> <a href="//www.slideshare.net/rotsuya/recaptchaskywayapi" title="reCAPTCHA と SkyWay の API 認証で手軽に利用できて不正利用に強いアプリを作ろう" target="_blank">reCAPTCHA と SkyWay の API 認証で手軽に利用できて不正利用に強いアプリを作ろう</a> </strong> from <strong><a href="//www.slideshare.net/rotsuya" target="_blank">Ryosuke Otsuya</a></strong> </div>
    <p>{`NTT コミュニケーションズ大津谷さんの LT です。SkyWay では 2018/9 月に`}<a parentName="p" {...{
        "href": "https://github.com/skyway/skyway-peer-authentication-samples"
      }}>{`API 認証`}</a>{`機能がリリースされたため、この機能と reCAPTCHA を使い API キーの不正利用を防ぎながらログインが不要なサービスを作れるというものでした。`}</p>
    <p>{`reCAPTCHA はこういう風に使えるんだ!という驚きと同時にプロトタイプなどをサッと作ったりするの大変よさそうな機能だと感じました。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`以上、SkyWay UG Tokyo #1 のレポートでした。SkyWay を現在使っている・使いたいというような開発者には気軽に情報交換もでき大変よいイベントでした。また、SkyWay 開発陣がユーザからの意見などをすぐに吸い上げてくれるのが改めて分かりました。`}</p>
    <p>{`次回の東京での開催はまだ未定なようですが、ご興味ある方はぜひいかがでしょうか。`}</p>
    <p>{`弊社では、SkyWay(WebRTC)を使った開発に興味があるエンジニアさん、ビデオチャットの UI を良くしたい!と思っているデザイナーさんの募集をしています。ご応募お待ちしています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/team/creator-story.html"
      }}>{`https://www.medley.jp/team/creator-story.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      